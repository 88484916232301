<template>
    <div class="row">
        <div class="center">
            <div
                class="col-md-12"
                v-for="(presentation, index) in cart.presentations"
                :key="index"
            >
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex m-b-30 align-items-center">
                            <div class="col-md-4">
                                <div
                                    class="
                                        new-arrival-product
                                        mb-4 mb-xxl-4 mb-md-0
                                    "
                                >
                                    <div class="new-arrivals-img-contnent">
                                        <img
                                            class="img-fluid img"
                                            :src="
                                                getCoverPath(presentation.cover)
                                            "
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div
                                    class="
                                        new-arrival-content
                                        position-relative
                                    "
                                >
                                    <div
                                        class="
                                            d-flex
                                            justify-content-between
                                            align-items-center
                                        "
                                    >
                                        <h4>
                                            <a href="#" class="text-black">{{
                                                presentation.name
                                            }}</a>
                                        </h4>
                                    </div>

                                    <p class="price">
                                        ${{ presentation.price }}
                                        MXN
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div
                                    class="
                                        d-flex
                                        justify-content-evenly
                                        align-items-center
                                    "
                                >
                                    <button
                                        :disabled="presentation.quantity === 0"
                                        @click.prevent="
                                            minus_product(presentation.id)
                                        "
                                        class="btn btn-primary mr-2"
                                    >
                                        -
                                    </button>
                                    <p class="mx-2">
                                        {{ presentation.quantity }}
                                    </p>
                                    <button
                                        @click.prevent="
                                            add_product(presentation.id)
                                        "
                                        class="btn btn-primary ml-2"
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="d-flex justify-content-center"
        v-if="cart.presentations.length > 0"
    >
        <div class="card card-size">
            <div class="card-body">
                <div class="d-flex flex-column justify-content-center">
                    <h5>Tu carrito</h5>
                    <p class="text-uppercase text-primary">
                        <strong
                            >Total de articulos #
                            {{ cart.presentations.length }}</strong
                        >
                    </p>
                    <p class="text-uppercase text-primary">
                        <strong>Subtotal $ {{ cart.subtotal }} MXN</strong>
                    </p>
                    <button
                        type="submit"
                        @click.prevent="postOrder"
                        class="btn btn-primary"
                    >
                        Realizar pedido
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { COOKIE_SESION_TKN } from "@/utils/constantes";
import { useRouter } from "vue-router";
export default {
    setup(props) {
        const router = useRouter();
        onMounted(() => {
            window.scrollTo(0, 0);
        });
        const store = useStore();
        const cart = ref(
            JSON.parse(localStorage.getItem("cart")) ?? {
                presentations: [],
                subtotal: 0,
            }
        );

        const add_product = (presentation_id) => {
            cart.value.presentations = cart.value.presentations.map((p) =>
                p.id === presentation_id
                    ? { ...p, quantity: (p.quantity += 1) }
                    : p
            );
            cart.value.subtotal = 0;
            cart.value.presentations.forEach((p) => {
                cart.value.subtotal += p.price * p.quantity;
            });
            localStorage.setItem("cart", JSON.stringify(cart.value));
        };
        const minus_product = (presentation_id) => {
            cart.value.presentations = cart.value.presentations.map((p) =>
                p.id === presentation_id
                    ? { ...p, quantity: (p.quantity -= 1) }
                    : p
            );
            cart.value.subtotal = 0;
            cart.value.presentations.forEach((p) => {
                cart.value.subtotal += p.price * p.quantity;
            });
            localStorage.setItem("cart", JSON.stringify(cart.value));
        };

        const postOrder = async () => {
            const token = Cookies.get(COOKIE_SESION_TKN);
            const response = await axios.post(
                `http://127.0.0.1:8000/api/orders`,
                cart.value,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log(response);
            if (response.status === 200) {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Pedido realizado",
                    showConfirmButton: false,
                    timer: 1500,
                });
                localStorage.setItem(
                    "cart",
                    JSON.stringify({
                        presentations: [],
                        subtotal: 0,
                    })
                );
                setTimeout(() => {router.push("/perfil")}, 3000);
            }
        };
        const getCoverPath = (path) =>
            !!path
                ? `${process.env.VUE_APP_API_GATEWAY}/storage/products/${path}`
                : "/assets/images/card/1.png";

        return {
            getCoverPath,
            cart,
            add_product,
            minus_product,
            postOrder,
        };
    },
};
</script>

<style scoped>
.img {
    max-width: 223px !important;
    max-height: 288px !important;
    object-fit: cover;
}
.center {
    margin: 0 auto;
}
@media (max-width: 390px) {
    .card-size {
        width: 90% !important;
    }
}

.card-size {
    width: 500px;
}
</style>
